import moment from "moment";

export function ticketsWon(data, paid, t) {
	const tickets = [];

	if (data) {
		data.map((ticket) => {
			// if (ticket && ticket.attributes && ticket.attributes.issuer && ticket.attributes.issuer.data && ticket.attributes.issuer.data.attributes) {
				if (ticket.attributes.status === 'win') {
					const TicketId = ticket.attributes.uid.split('-')[0] || '';
					const TicketSeller = ticket?.attributes?.issuer?.data?.attributes?.username || '';
					const TicketStatus = (ticket?.attributes?.paid ? "paid" : "unpaid") || '';
					const TicketDate = TicketStatus === "unpaid" ? moment(ticket?.attributes?.createdAt).format('DD MMM YYYY h:mm a') : moment(ticket?.attributes?.paidAt).format('DD MMM YYYY h:mm a');
					const TicketProfit = ticket?.attributes?.win || '';
		
					if (paid === "All" || (paid !== "All" && TicketStatus === paid)) {
						tickets.push({
							id: TicketId,
							uid: TicketSeller,
							status: t(TicketStatus),
							date: TicketDate,
							profit: TicketProfit
						})
					}
				}
			// }
		})
	}
	return tickets;
}