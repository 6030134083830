import axios from "axios";
import { useEffect, useState } from "react";

export const useTypologie = () => {
	const token = localStorage.getItem("token");
	const [elements, setElements] = useState();

	useEffect(() => {
		const getTypologie = async () => {
			try {
				const res = await axios.get(`${process.env.API_PROTOCOL}://${process.env.API_URL}/typologie?populate[elements]=true`, {
					headers: {
					  Authorization: `Bearer ${token}`,
				}});
				setElements(res.data);
			} catch (error) {
				
			}
		}
		getTypologie();
	}, []);
	return {elements};
} 