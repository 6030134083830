import { useQuery } from 'react-query';
import request from '@request';
import { convertObjectToSearchParams } from '@utils/convertToSearchParams';

const getTickets = ({
  queryKey: [, { search, page, agent, endDate, startDate, city, zone, region }]
}) => {
  const searchParams = {
    populate: 'paidBy,issuer',
    'filters[uid][$startsWith]': search,
    'pagination[page]': page,
    'filters[createdAt][$gte]': startDate,
    'filters[createdAt][$lte]': endDate,
    'filters[issuer][username][$contains]': agent,
    'sort[createdAt]': 'DESC',
    // The $or condition for filtering by issuer ussd or region/city/zone
    'filters[$or][0][issuer][username][$eq]': 'ussd',  // Include tickets with issuer 'ussd'
    'filters[$or][1][issuer][username][$ne]': 'ussd',  // Include non-ussd tickets that match filters
    'filters[$or][1][selling_point][zone][city][id][$eq]': city,
    'filters[$or][1][selling_point][zone][id][$eq]': zone,
    'filters[$or][1][selling_point][zone][city][region][name][$eq]': region
  };
  
  return request.get(`/tickets?${convertObjectToSearchParams(searchParams)}`);
};

export const useTickets = ({ search, page, agent, endDate, startDate, city, zone, region }) =>
  useQuery(
    ['tickets', { search, page, agent, endDate, startDate, city, zone, region }],
    getTickets
  );
